import { render, staticRenderFns } from "./Status.vue?vue&type=template&id=be31c242&scoped=true&"
import script from "./Status.vue?vue&type=script&lang=ts&"
export * from "./Status.vue?vue&type=script&lang=ts&"
import style0 from "./Status.vue?vue&type=style&index=0&id=be31c242&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be31c242",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Light: require('/home/runner/work/dreamlink.cloud/dreamlink.cloud/components/Light.vue').default,Button: require('/home/runner/work/dreamlink.cloud/dreamlink.cloud/node_modules/@opendreamnet/nuxtjs-base/components/Button.vue').default})
