import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0e16f90d&scoped=true&"
var script = {}
import style0 from "./default.vue?vue&type=style&index=0&id=0e16f90d&prod&lang=scss&scoped=true&"
import style1 from "./default.vue?vue&type=style&index=1&id=0e16f90d&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e16f90d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutHeader: require('/home/runner/work/dreamlink.cloud/dreamlink.cloud/components/Layout/Header.vue').default,NodeError: require('/home/runner/work/dreamlink.cloud/dreamlink.cloud/components/Node/Error.vue').default,LayoutFooter: require('/home/runner/work/dreamlink.cloud/dreamlink.cloud/components/Layout/Footer.vue').default})
