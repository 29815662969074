
import Vue from 'vue'
import type { BWResult } from 'ipfs-core-types/dist/src/stats/index'
import { isNil } from 'lodash'

interface IData {
  payload: BWResult | null
  fetchInterval: NodeJS.Timeout | null
}

export default Vue.extend({
  data: (): IData => ({
    payload: null,
    fetchInterval: null
  }),

  computed: {
    avatarURL(): string {
      return this.$accessor.ipfs.avatarURL
    },

    download(): number | null {
      const value = this.payload?.rateIn
      return isNil(value) || isNaN(value) ? null : value
    },

    upload(): number | null {
      const value = this.payload?.rateOut
      return isNil(value) || isNaN(value) ? null : value
    }
  },

  created() {
    // this.fetchInterval = setInterval(this.fetchSpeed.bind(this), 2000)
  },

  methods: {
    async fetchSpeed() {
      if (!this.$ipfs.started) {
        return
      }

      if (!this.$ipfs.api) {
        throw new Error('IPFS API is undefined!')
      }

      for await (const data of this.$ipfs.api.stats.bw()) {
        this.payload = data
      }
    }
  }
})
