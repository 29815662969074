
import Vue from 'vue'

export default Vue.extend({
  computed: {
    nodeError(): Error | null {
      return this.$accessor.ipfs.nodeError
    },

    message(): string {
      if (!this.nodeError) {
        return ''
      }

      if (this.nodeError.message.includes('Failed to fetch')) {
        return 'The connection to the external node could not be established, make sure that the Endpoint is correct or change the node type to "Browser".'
      }

      return this.nodeError.toString()
    }
  }
})
