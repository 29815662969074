
import Vue from 'vue'

export default Vue.extend({
  props: {
    value: {
      type: Boolean,
      default: false
    },
    ping: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    lightCss(): any {
      return {
        light__on: this.value,
        light__off: !this.value
      }
    }
  }
})
