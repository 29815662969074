import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=02c61fab&scoped=true&"
var script = {}
import style0 from "./Footer.vue?vue&type=style&index=0&id=02c61fab&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02c61fab",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FooterVersion: require('/home/runner/work/dreamlink.cloud/dreamlink.cloud/components/Footer/Version.vue').default,FooterCID: require('/home/runner/work/dreamlink.cloud/dreamlink.cloud/components/Footer/CID.vue').default,FooterCopyright: require('/home/runner/work/dreamlink.cloud/dreamlink.cloud/components/Footer/Copyright.vue').default,FooterMirrors: require('/home/runner/work/dreamlink.cloud/dreamlink.cloud/components/Footer/Mirrors.vue').default,FooterIPFS: require('/home/runner/work/dreamlink.cloud/dreamlink.cloud/components/Footer/IPFS.vue').default})
