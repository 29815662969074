
import Vue from 'vue'

export default Vue.extend({
  data: () => ({
    scrollTop: 0
  }),

  computed: {
    headerClass() {
      return {
        'header--scrolled': this.scrollTop > 80
      }
    }
  },

  mounted() {
    window.addEventListener('scroll', () => {
      // Get the scroll position
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop
    })
  }
})
